/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/extensions */
import {
  BaseSimpleField,
  FieldOrGroup,
  Fields,
  MultiSelectFieldSettings,
} from '@react-awesome-query-builder/antd';
import { Card, Button } from 'antd';
import { useEffect, useState } from 'react';
import SQL_QUERY_BUILDER_CONSTANT from './constant/SQL_QUERY_BUILDER_CONSTANT';
import SQLMode from './mode_components/sqlMode';
import SingleMode from './mode_components/singleMode';

const SQLQueryBuilder = ({
  onSave,
  value = {},
  config,
}: {
  onSave: Function;
  value: any;
  config: any;
}) => {
  const [fields, setFields] = useState<Fields>(null);
  const createFields = (columns) => {
    const finalFields: Fields = {};
    columns.forEach((column: any) => {
      const finalColumnObj: BaseSimpleField = {
        type: '',
        label: column.name || '',
        valueSources: ['value'],
      };
      switch (column.dataType) {
        case 'string':
          finalColumnObj.type = 'text';
          break;

        default:
          finalColumnObj.type = column.dataType;
          break;
      }
      if (column.columnType === 'master') {
        finalColumnObj.type = 'select';
        finalColumnObj.fieldSettings = {
          listValues: column.master,
        } as MultiSelectFieldSettings;
      }

      finalFields[column.name] = finalColumnObj;
    });
    return finalFields;
  };
  useEffect(() => {
    if (config.columns) {
      setFields(createFields(config.columns));
    }
  }, [config]);

  if (!fields) return <div>Loading...</div>;

  console.log(fields, 'fieldsss')

  return (
    <div key={JSON.stringify(fields)}>
      {config.mode === SQL_QUERY_BUILDER_CONSTANT.MODE.SQL ? (
        <SQLMode value={value} fields={fields} onSave={onSave} />
      ) : null}

      {config.mode === SQL_QUERY_BUILDER_CONSTANT.MODE.SINGLE ? (
        <SingleMode value={value} fields={fields} onSave={onSave} />
      ) : null}
    </div>
  );
};
export default SQLQueryBuilder;
